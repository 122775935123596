<template>
   <div class="amc-switches d-flex align-self-center" @click="clicked" :textLeft="textLeft" :textRight="textRight">
      <span class="option-1 option" :class="{ 'text-primary': !isActive && Select }"> {{ textLeft }} </span>
      <span class="option-2 option" :class="{ 'text-primary': isActive }"> {{ textRight }} </span>
      <div class="switch-ball" :class="{ switch: isActive }"></div>
   </div>
</template>

<script>
export default {
   name: 'AmcSwitches',
   props: {
      textRight: {
         type: [String, Number],
         default: 'textRight',
      },
      textLeft: {
         type: [String, Number],
         default: 'textLeft',
      },
      Select: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         isActive: true,
      };
   },
   methods: {
      clicked() {
         this.isActive = !this.isActive;
         this.$emit('click');
      },
   },
};
</script>

<style lang="scss" scoped>
.amc-switches {
   cursor: pointer;
   height: 42px;
   width: 191px;
   border: 1px solid #e1e1e1;
   box-sizing: border-box;
   border-radius: 100px;
   position: relative;
   .option {
      font-weight: 500;
      color: #919699;
   }
   .option-1 {
      position: absolute;
      left: 15%;
      bottom: 22%;
   }
   .option-2 {
      position: absolute;
      right: 20%;
      bottom: 22%;
   }
   .switch-ball {
      background: #e5efff !important;
      height: 80%;
      width: 50%;
      border-radius: 100px;
      margin: 4px;
      cursor: pointer;
      transition: ease 0.7s;
      position: absolute;
      z-index: -10;
   }
}
.switch {
   transform: translateX(90%);
   background: #e5efff !important;
}
</style>
